import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useDark } from './utils';

const lightTheme = createTheme({
	palette: {
		mode: "light",
		background: {
			paper: "#fff"
		}
	}
})

const darkTheme = createTheme({
	palette: {
		mode: "dark",
		background: {
			paper: "#171717"
		}
	}
});

function Page() {
	let dark = useDark();

	return (
		<React.StrictMode>
			<ThemeProvider theme={dark ? darkTheme : lightTheme}>
				<App />
			</ThemeProvider>
		</React.StrictMode>
	)
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<Page />);
