import React from "react";

export interface CredentialsModel {
    setCredentialsModel(value: string): void
}

export const CredentialsModelContext = React.createContext<CredentialsModel>({
    setCredentialsModel(value) {
        // NOOP
    },
});