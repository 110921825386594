import { useEffect, useState } from 'react';
import './App.css';
import NavBar from './NavBar';
import Clients from './Clients';
import { User, UserContext } from './contexts/userContext';
import { Box, Modal } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import lightStyle from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light';
import darkStyle from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-dark';
import { CredentialsModelContext } from './contexts/credentialsModelContext';
import { useDark } from './utils';

const modalStyle = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	bgcolor: 'background.paper',
	border: '2px solid #222',
	boxShadow: 24,
	p: 4,
	color: "#fff"
};

function App() {
	const [search, setSearch] = useState("");
	const [resource, setResource] = useState("");
	const [action, setAction] = useState(0);
	const [user, setUser] = useState<User | undefined | null>(null);
	const [credentialsModel, setCredentialsModel] = useState<string | null>(null)
	useEffect(() => {
		fetch("/api/user").then(res => {
			if (res.status === 401) {
				return setUser(undefined);
			}
			if (res.status === 403) {
				return setUser(null);
			}
			res.json().then(user => setUser(user));
		});
	}, []);

	let dark = useDark();

	return (
		<UserContext.Provider value={user}>
			{credentialsModel ? (
				<Modal
					open={true}
					onClose={() => setCredentialsModel(null)}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={modalStyle} style={{border: "none"}}>
						<SyntaxHighlighter language="json" style={dark ? darkStyle : lightStyle} className="background">
							{credentialsModel}
						</SyntaxHighlighter>
					</Box>
				</Modal>
			) : null}
			<CredentialsModelContext.Provider value={{ setCredentialsModel }}>
				<NavBar
					search={search}
					setSearch={setSearch}

					resource={resource}
					setResource={setResource}

					action={action}
					setAction={setAction}
				/>
				<Clients search={search} resource={resource} action={action} />
			</CredentialsModelContext.Provider>
		</UserContext.Provider>
	);
}

export default App;
