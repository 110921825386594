import React, { useContext } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TableCell, TableHead, TableRow, TableBody, IconButton, Collapse, Box, Typography, Table, TableContainer, Paper, Modal, Menu, MenuItem, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, Select, DialogActions, Button, InputLabel } from '@mui/material';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';

import RefreshIcon from '@mui/icons-material/Refresh';
import PinIcon from '@mui/icons-material/PushPin';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import { UserContext } from './contexts/userContext';
import { CredentialsModelContext } from './contexts/credentialsModelContext';
import { generateDeviceCodeAndWait } from './utils';

SyntaxHighlighter.registerLanguage('json', json);

function getActionNames(action: number) {
    if (action === 0) return "NONE";
    if (action === 15) return "ALL";
    return ["CREATE", "READ", "UPDATE", "DELETE", "DENY"].filter((_, i) => action & (2 ** i)).join(" ");
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #222',
    boxShadow: 24,
    p: 4,
    color: "#fff"
};


function sortPermissions(perms: Permission[]) {
	return perms.sort((a,b) => {
		if (a.resource !== b.resource) {
			return a.resource < b.resource
				? -1
				: 1;
		}

		return a.action < b.action
			? -1
			: 1;
	});
}


function PermList(props: { perms: Permission[] }) {
    return (
        <>
            <TableHead>
                <TableRow>
                    <TableCell>Resource</TableCell>
                    <TableCell>Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sortPermissions(props.perms).map((perm, i) => (
                    <TableRow key={i}>
                        <TableCell>
                            {perm.resource}
                        </TableCell>
                        <TableCell>{getActionNames(perm.action)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </>
    )
}

async function performDeviceCodeAuth(client: Client, params?: any) {
    let deviceAuthorization = await generateDeviceCodeAndWait();
    let auth = await fetch(`/api/clients/${client.id}/auth`, {
        method: "POST",
        headers: {
            'Content-Type': "application/json"
        },
        body: JSON.stringify({ grant_type: "device_code", device_code: deviceAuthorization.device_code, client_id: deviceAuthorization.client_id, ...params })
    }).then(r => r.json());
    if (auth.errorMessage) {
        throw new Error(auth.errorMessage)
    }
    return auth;
}

function GenerateTokenDialog(props: { client: Client, type: string, handleClose: () => void }) {
    const [account, setAccount] = React.useState<string | null>(null);
    const [accounts, setAccounts] = React.useState<Account[]>([]);
    const [tokenType, setTokenType] = React.useState("none");
    const [tokenTypes, setTokenTypes] = React.useState<string[]>([]);
    const [deployment, setDeployment] = React.useState("none");
    const [deployments, setDeployments] = React.useState<Deployment[]>([]);

    React.useEffect(() => {
        fetch(`/api/clients/${props.client.id}/auth`).then(r => r.json()).then(auth => {
            if (props.type === "account") {
                setAccounts(auth.accounts);
                if (auth.accounts.length > 0) {
                    setAccount("0");
                }
                setTokenTypes(auth.tokenTypes);
            }
            setDeployments(auth.deployments);
        });

        if (props.type === "client") {
            if (props.client.eos) {
                setTokenTypes(["eg1", "epic_id"]);
            } else {
                setTokenTypes(["eg1"]);
            }
        }
    }, [props.client.id, props.type, props.client.eos]);

    const { setCredentialsModel } = React.useContext(CredentialsModelContext);

    async function generate() {
        props.handleClose();

        let params: any = {};
        if (tokenType !== "none") {
            params.token_type = tokenType;
        }
        if (deployment !== "none") {
            params.deployment_id = deployment;
        }


        if (props.type === "client") {
            let authData = await fetch(`/api/clients/${props.client.id}/auth`, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    grant_type: "client_credentials",
                    ...params
                })
            }).then(r => r.json());
            if (authData.errorMessage) {
                alert(authData.errorMessage);
                return;
            }
            setCredentialsModel(JSON.stringify(authData, null, 4));
        }
        if (props.type === "account") {
            if (account !== null) {
                let authData = await fetch(`/api/clients/${props.client.id}/auth`, {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json"
                    },
                    body: JSON.stringify({
                        ...accounts[Number(account)]!.params,
                        ...params
                    })
                }).then(r => r.json());
                if (authData.errorMessage) {
                    alert(authData.errorMessage);
                    return;
                }
                setCredentialsModel(JSON.stringify(authData, null, 4));
            } else {
                try {
                    setCredentialsModel(JSON.stringify(await performDeviceCodeAuth(props.client, params), null, 4));
                } catch (e) {
                    alert(String(e));
                }
            }
        }
    }


    return (
        <Dialog open={true} onClose={props.handleClose} fullWidth>
            <DialogTitle>Generate {props.type[0].toUpperCase() + props.type.substring(1)} Token</DialogTitle>
            <DialogContent>
                {props.type === "account" && (
                    <>
                        <InputLabel id="generate-token-id-label">Account</InputLabel>
                        <Select
                            value={account ?? "prompt"}
                            label="Account"
                            labelId="generate-token-id-label"
                            fullWidth
                            variant="standard"
                            style={{ marginBottom: 10 }}
                            onChange={e => setAccount(e.target.value !== "prompt" ? e.target.value : null)}
                        >
                            {accounts.map((account, i) => (
                                <MenuItem key={i} value={i}>{account.displayName} [{account.id}]</MenuItem>
                            ))}
                            <MenuItem value="prompt">Prompt</MenuItem>
                        </Select>
                    </>
                )}
                <InputLabel id="generate-token-type-label">Token Type</InputLabel>
                <Select
                    label="Token Type"
                    labelId="generate-token-type-label"
                    fullWidth
                    variant="standard"
                    value={tokenType}
                    onChange={e => setTokenType(e.target.value)}
                    style={{ marginBottom: 10 }}
                >
                    <MenuItem value="none">- none -</MenuItem>
                    {tokenTypes.map(tokenType => (
                        <MenuItem key={tokenType} value={tokenType}>{tokenType}</MenuItem>
                    ))}
                </Select>
                <InputLabel id="generate-token-deployment-label">Deployment ID</InputLabel>
                <Select
                    label="Deployment ID"
                    labelId="generate-token-deployment-label"
                    fullWidth
                    variant="standard"
                    value={deployment}
                    onChange={e => setDeployment(e.target.value as string)}
                >
                    <MenuItem value="none">- none - </MenuItem>
                    {deployments.map(deployment => (
                        <MenuItem key={deployment.deployment_id} value={deployment.deployment_id}>{deployment.deployment_id} [{deployment.sandbox_id}]</MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={generate}>Generate</Button>
            </DialogActions>
        </Dialog>
    );
}

interface ContextMenuData {
    client: Client;
    x: number;
    y: number;
}

function ClientContextMenu(props: { contextMenu: ContextMenuData | null, handleClose: () => void, setPinnedClients: (pinnedClients: string[]) => void  }) {
    const clientRef = React.useRef<Client | null>(null);
    const [generateTokenType, setGenerateTokenType] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (props.contextMenu?.client) {
            clientRef.current = props.contextMenu.client;
        }
    }, [props.contextMenu?.client])

    const client = props.contextMenu?.client ?? clientRef.current;
    const pinned = client ? JSON.parse(localStorage.getItem("pinnedClients") || "[]").includes(client.id) ?? false : false;

    function pinClient() {
        if (!client) return;
        props.handleClose();

        let pinnedClients = JSON.parse(localStorage.getItem("pinnedClients") || "[]") as string[];
        if (pinned) pinnedClients.splice(pinnedClients.indexOf(client.id), 1);
        else pinnedClients.push(client.id);

        localStorage.setItem("pinnedClients", JSON.stringify(Array.from(pinnedClients)));
        props.setPinnedClients(pinnedClients);
    }

    async function refreshClient() {
        if (!client) return;

        props.handleClose();
        let authData = await fetch(`/api/clients/${client.id}/refresh`, {
            method: "POST"
        }).then(r => r.json());
        if (authData.errorMessage) {
            alert(authData.errorMessage);
            return;
        }
        window.location.reload();
    }

    async function deleteClient() {
        if (!client) return;

        props.handleClose();
        let authData = await fetch(`/api/clients/${client.id}`, {
            method: "DELETE"
        }).then(r => r.json());
        if (authData.errorMessage) {
            alert(authData.errorMessage);
            return;
        }
        window.location.reload();
    }

    async function generateClientCredentials(token_type?: string) {
        if (!client) return;

        props.handleClose();
        setGenerateTokenType("client");
    }

    async function generateAccountCredentials(token_type?: string) {
        if (!client) return;

        props.handleClose();
        setGenerateTokenType("account");
    }

    let user = useContext(UserContext);


    return (
        <>
            {generateTokenType !== null && <GenerateTokenDialog type={generateTokenType} client={client!} handleClose={() => setGenerateTokenType(null)} />}
            <Menu
                open={props.contextMenu !== null}
                onClose={props.handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    props.contextMenu !== null
                        ? { top: props.contextMenu.y, left: props.contextMenu.x }
                        : undefined
                }
            >
                <MenuItem onClick={refreshClient}><ListItemIcon><RefreshIcon /></ListItemIcon><ListItemText primary="Refresh" /></MenuItem>
                <MenuItem onClick={pinClient}><ListItemIcon><PinIcon /></ListItemIcon><ListItemText primary={pinned ? "Unpin Client" : "Pin Client"} /></MenuItem>
                {client?.enabled && client.grantTypes?.includes("client_credentials") ? (
                    <MenuItem onClick={() => generateClientCredentials()}><ListItemIcon><SettingsIcon /></ListItemIcon> <ListItemText primary="Generate Client Credentials" /></MenuItem>
                ) : null}
                {client?.enabled && client.perms.account ? <MenuItem onClick={() => generateAccountCredentials()}><ListItemIcon><AccountCircleIcon /></ListItemIcon><ListItemText primary="Generate Account Credentials" /></MenuItem> : null}
                {user?.perms.find(perm => perm.resource === "egs:clients" && (perm.action & 8) === 8) ? <MenuItem onClick={deleteClient}><ListItemIcon><DeleteIcon style={{ color: "red" }} /></ListItemIcon><ListItemText primary="Delete" style={{ color: "red" }} /></MenuItem> : null}
            </Menu>
        </>
    );
}

function Row(props: { client: Client, pinned: boolean, setContextMenu: (menu: ContextMenuData | null) => void }) {
    const { client, pinned } = props;
    const [open, setOpen] = React.useState(false);

    function handleContextMenu(event: React.MouseEvent) {
        if (window.getSelection() && window.getSelection()!.toString() !== "") return;
        event.preventDefault();
        props.setContextMenu({
            client,
            x: event.clientX + 2,
            y: event.clientY - 6,
        });
    }

    return (
        <>
            <TableRow onContextMenu={handleContextMenu} className={`${client.enabled ? "enabled" : "disabled"}${pinned ? " pinned" : ""}`}>
                <TableCell style={{ borderBottom: "unset" }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell style={{ borderBottom: "unset" }}>{client.id}</TableCell>
                <TableCell style={{ borderBottom: "unset" }}>{client.name}</TableCell>
                <TableCell style={{ borderBottom: "unset" }}>{client.secret ?? "Unknown"}</TableCell>
                <TableCell style={{ borderBottom: "unset" }}>{client.service}</TableCell>
                <TableCell style={{ borderBottom: "unset" }}>{client.grantTypes?.join(", ") ?? "Unknown"}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} className="permissionsTable">
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <Typography variant="h6" gutterBottom component="th">
                                            Client Permissions
                                        </Typography>
                                    </TableRow>
                                </TableHead>
                                {client.perms.client ? (
                                    <PermList perms={client.perms.client} />
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Unknown</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                                <TableHead>
                                    <TableRow>
                                        <Typography variant="h6" gutterBottom component="th">
                                            Account Permissions
                                        </Typography>
                                    </TableRow>
                                </TableHead>
                                {client.perms.account ? (
                                    <PermList perms={client.perms.account} />
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Unknown</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
interface Props {
    search: string,
    resource: string,
    action: number
}
interface State {
    clients: Array<any>,
    error: string | null,
    contextMenu: ContextMenuData | null,
    pinnedClients: string[]
}
interface Permission {
    resource: string
    action: number
}
interface Client {
    id: string
    name: string
    secret: string | null
    enabled: boolean
    eos: boolean | null
    features: string[] | null
    service: string | null
    redirectUrl: string | null
    allowedScope: string[]
    grantTypes: string[] | null
    perms: {
        client?: Permission[]
        account?: Permission[]
    }
}
interface Deployment {
    deployment_id: string
    product_id: string
    sandbox_id: string
}
interface Account {
    id: string
    displayName: string
    params: any
}

export default class Clients extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            clients: [],
            error: null,
            contextMenu: null,
            pinnedClients: JSON.parse(localStorage.getItem("pinnedClients") || "[]"),
        }

        this.filterClients = this.filterClients.bind(this);
        this.setContextMenu = this.setContextMenu.bind(this);
    }
    componentDidMount() {
        fetch("/api/clients").then().then(async r => {
            if (r.status === 401) {
                window.location.href = "/api/auth";
                return;
            }
            if (r.status === 403) {
                let json = await r.json();
                this.setState({
                    error: json.errorMessage
                });
                return;
            }

            let clients = await r.json();
            (window as any).clients = clients.clients;
            this.setState(clients)
        });
    }
    setContextMenu(menu: ContextMenuData | null) {
        this.setState({
            contextMenu: menu
        });
    }
    filterClients(client: Client) {
        return this.filterClientsBySearch(client) && this.filterClientsByPerm(client);
    }
    filterClientsBySearch(client: Client) {
        let search = this.props.search.toLowerCase();
        if (client.id.toLowerCase().includes(search)) {
            return true;
        }
        if (client.name.toLowerCase().includes(search)) {
            return true;
        }
        if (client.secret?.toLowerCase().includes(search)) {
            return true;
        }
        if (client.service?.toLowerCase().includes(search)) {
            return true;
        }
        for (let type in client.perms) {
            for (let i = 0; i < client.perms[type as keyof Client["perms"]]!.length; i++) {
                if (client.perms[type as keyof Client["perms"]]![i].resource.toLowerCase().includes(search)) {
                    return true;
                }
            }
        }
        return false;
    }

    filterClientsByPerm(client: Client) {
        if (this.props.resource) {
            for (let type in client.perms) {
                let allowed = false;
                for (let i = 0; i < client.perms[type as keyof Client["perms"]]!.length; i++) {
                    if ((client.perms[type as keyof Client["perms"]]![i].action & this.props.action) === this.props.action || client.perms[type as keyof Client["perms"]]![i].action === 16) {
                        if (new RegExp("^" + client.perms[type as keyof Client["perms"]]![i].resource.replace(/\*/g, "[^:]*") + "$").test(this.props.resource)) {
                            allowed = client.perms[type as keyof Client["perms"]]![i].action !== 16;
                            if (!allowed) {
                                break;
                            }
                        }
                    }
                }
                if (allowed) return true;
            }
            return false;
        }
        return true;
    }
    render() {
        if (this.state.error !== null) {
            return (
                <Modal
                    open={true}
                    onClose={() => { }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Error
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {this.state.error}
                        </Typography>
                    </Box>
                </Modal>
            )
        }

        const { pinnedClients } = this.state;
        return (
            <>
                <ClientContextMenu contextMenu={this.state.contextMenu} handleClose={() => this.setContextMenu(null)} setPinnedClients={(pinnedClients) => this.setState({ pinnedClients })} />
                <TableContainer component={Paper} style={{ maxHeight: "calc(100vh - 64px)", overflow: "auto", borderRadius: 0 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableCell" />
                                <TableCell className="tableCell">ID</TableCell>
                                <TableCell className="tableCell">Name</TableCell>
                                <TableCell className="tableCell">Secret</TableCell>
                                <TableCell className="tableCell">Service</TableCell>
                                <TableCell className="tableCell">Grant Types</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.clients.filter(this.filterClients).sort((a, b) => (pinnedClients.includes(b.id) ? 1 : 0) - (pinnedClients.includes(a.id) ? 1 : 0)).map((client) => (
                                <Row key={client.id} client={client} setContextMenu={this.setContextMenu} pinned={this.state.pinnedClients.includes(client.id)} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
}