import React from "react";

interface Permission {
    resource: string
    action: number
}

export interface User {
    id: string
    perms: Permission[]
}

export const UserContext = React.createContext<User | undefined | null>(undefined);