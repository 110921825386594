import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Input, ListItem, Menu, MenuItem, Select, TextField, Toolbar, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import LoginIcon from '@mui/icons-material/Login';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import React, { useContext } from 'react';
import { UserContext } from './contexts/userContext';
import { CredentialsModelContext } from './contexts/credentialsModelContext';
import { generateDeviceCodeAndWait } from './utils';
interface Props {
    search: string,
    setSearch: React.Dispatch<React.SetStateAction<string>>,

    resource: string,
    setResource: React.Dispatch<React.SetStateAction<string>>,

    action: number,
    setAction: React.Dispatch<React.SetStateAction<number>>
}

interface AddClientDialogProps {
    open: boolean
    setOpen: (open: boolean) => void
}

function AddClientDialog(props: AddClientDialogProps) {
    const [processing, setProcessing] = React.useState(false);
    const [id, setID] = React.useState("");
    const [secret, setSecret] = React.useState("");
    const [deployment, setDeployment] = React.useState("");

    async function addClient() {
        setProcessing(true);
        try {
            let res = await fetch("/api/clients", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    clientId: id,
                    clientSecret: secret !== "" ? secret : null,
                    deployment: deployment !== "" ? deployment : null
                })
            }).then(r => r.json());
            if (res.errorMessage) {
                alert(res.errorMessage);
                setProcessing(false);
                return;
            }
            window.location.reload();
        } catch (e) {
            setProcessing(false)
        }
    }

    return (
        <Dialog open={props.open} onClose={() => !processing && props.setOpen(false)}>
            <DialogTitle>Add Client</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Client ID"
                    value={id}
                    onChange={e => setID(e.target.value.replace(/\s/g, ""))}
                    disabled={processing}
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    label="Client Secret"
                    value={secret}
                    onChange={e => setSecret(e.target.value.replace(/\s/g, ""))}
                    disabled={processing}
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    label="Deployment ID"
                    value={deployment}
                    onChange={e => setDeployment(e.target.value.replace(/\s/g, ""))}
                    disabled={processing}
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={processing} onClick={() => props.setOpen(false)}>Cancel</Button>
                <Button disabled={processing} onClick={addClient}>Add</Button>
            </DialogActions>
        </Dialog>
    );
}

export default function NavBar(props: Props) {
    const [filterOpen, setFilterOpen] = React.useState(false);
    const [addClientOpen, setAddClientOpen] = React.useState(false);
    const anchorEl = React.useRef<HTMLButtonElement>(null);

    const { setCredentialsModel } = React.useContext(CredentialsModelContext);

    async function generateExchangeCode() {
        let deviceAuthorization = await generateDeviceCodeAndWait();
        let exchangeCode = await fetch(`/api/exchange`, {
            method: "POST",
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({ grant_type: "device_code", device_code: deviceAuthorization.device_code, client_id: deviceAuthorization.client_id })
        }).then(r => r.json());
        if (exchangeCode.errorMessage) {
            alert(exchangeCode.errorMessage);
            return;
        }
        setCredentialsModel(JSON.stringify(exchangeCode, null, 4))
    }

    let user = useContext(UserContext);

    return (
        <AppBar position="static" className="navbar">
            <Toolbar variant="regular">
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    Epic Games Client Documentation
                </Typography>
                <IconButton
                    onClick={() => generateExchangeCode()}
                    color="inherit"
                    ref={anchorEl}
                >
                    <LinkIcon />
                </IconButton>
                <IconButton
                    onClick={() => setAddClientOpen(true)}
                    color="inherit"
                    ref={anchorEl}
                >
                    <AddIcon />
                </IconButton>
                <IconButton
                    onClick={() => setFilterOpen(true)}
                    color="inherit"
                    ref={anchorEl}
                >
                    <FilterListIcon />
                </IconButton>
                {user === undefined ? (
                    <IconButton
                        onClick={() => window.location.href = "/api/auth"}
                        color="inherit"
                        ref={anchorEl}
                    >
                        <LoginIcon />
                    </IconButton>
                ) : null}
                <AddClientDialog open={addClientOpen} setOpen={setAddClientOpen} />
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl.current}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={filterOpen}
                    onClose={() => setFilterOpen(false)}
                    style={{ top: 35 }}
                >
                    <div />
                    <Typography variant="h6" component="div" style={{ marginLeft: 16 }}>
                        Search
                    </Typography>
                    <ListItem>
                        <Input style={{ minWidth: 500, width: "100%" }} value={props.search} onChange={e => props.setSearch(e.target.value)} />
                    </ListItem>
                    <Typography variant="h6" component="div" style={{ marginLeft: 16 }}>
                        Permission
                    </Typography>
                    <ListItem>
                        <Input style={{ minWidth: 400 }} value={props.resource} onChange={e => props.setResource(e.target.value)} spellCheck={false} />
                        <Select variant="standard" style={{ minWidth: 100 }} value={props.action} onChange={e => props.setAction(Number(e.target.value))}>
                            <MenuItem value={0}>ANY</MenuItem>
                            <MenuItem value={1}>CREATE</MenuItem>
                            <MenuItem value={2}>READ</MenuItem>
                            <MenuItem value={4}>UPDATE</MenuItem>
                            <MenuItem value={8}>DELETE</MenuItem>
                            <MenuItem value={15}>ALL</MenuItem>
                        </Select>
                    </ListItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}