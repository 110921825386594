import { useEffect, useState } from "react";

function waitForClose(popup: Window) {
    return new Promise<void>(resolve => {
        const timer = setInterval(() => {
            if (popup.closed) {
                clearInterval(timer)
                resolve();
            }
        })
    });
}

export async function generateDeviceCodeAndWait() {
    let deviceAuthorization = await fetch("/api/deviceAuthorization", {
        method: "POST"
    }).then(r => r.json());
    const w = 448;
    const h = 796;

    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    let authWindow = window.open(deviceAuthorization.verification_uri_complete, '', `width=${w / systemZoom},height=${h / systemZoom},top=${top},left=${left}`);
    await waitForClose(authWindow!);
    return deviceAuthorization;
}

export function useDark() {
    let [dark, setDark] = useState(() => window.matchMedia("(prefers-color-scheme: dark)").matches);

    useEffect(() => {
        const darkMedia = window.matchMedia("(prefers-color-scheme: dark)");

        function onDarkChange() {
            setDark(darkMedia.matches);
        }

        darkMedia.addEventListener("change", onDarkChange);

        return () => {
            darkMedia.removeEventListener("change", onDarkChange);
        }
    }, []);

    return dark;
}